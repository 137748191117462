<template>
  <div class="StudentMembership">
    <notification-popup 
      :dialog="dialogCancelMembership"
      :contentMsg="$t('DescbribeCancel')"
      :headerMsg="$t('CancelMembership')"
      colorheaderMsg="error--text"
      nameBtnNo="No"
      nameBtnYes="Yes"
      colorIcon = "danger"
      :onHandlerNo="clickNoCancelMembership"
      :onHandlerYes="clickYesCancelMembership"/>
    <!-- <div >
        <v-container>
            <div align="center">
                <v-img
                class="image-head"
                :src="require('@/assets/profile/watch-membership.svg')">
                </v-img>
            </div>
            <p class="title-no-promotion">Membership registration is being processed</p>
            <p class="describe-null-active">
            The process may take up to 3 working days,
            of further verification needed, our team will reach you
            through one of following phone number.
            </p>
        </v-container>
    </div> -->


    <div class="item-add">
      <div align="center">
          <img
          class="img-premium"
          :src="require('@/assets/profile/student-membership.svg')"/>
          <p class="title-student-membership mb-1 mt-2">{{$t('titleStudentMembership')}}</p>
          <p class="describe-student-membership">{{$t('describeYourStudentMembership')}}</p>
      </div>
      <v-container class="">
        <v-row align="center" class="px-5 border-bottom pb-1">
          <v-col cols="2" class="pa-2">
            <v-img
              max-height="150"
              max-width="250"
              :src="require('@/assets/profile/list.svg')"
            ></v-img>
          </v-col>
          <v-col cols="10">
            <p class="title-detail">{{$t('titleStudentMembership1')}}</p>
                <p class="describe-detail mb-0">{{$t('describeStudentMembership1')}}</p>
          </v-col>
        </v-row>
        <v-row align="center" class="px-5 border-bottom py-1">
          <v-col cols="2" class="pa-2">
            <v-img
              :src="require('@/assets/profile/checklist.svg')"
            ></v-img>
          </v-col>
          <v-col cols="10">
            <p class="title-detail">{{$t('titleStudentMembership2')}}</p>
                <p class="describe-detail mb-0">{{$t('describeStudentMembership2')}}</p>
          </v-col>
        </v-row>
        <v-row align="center" class="px-5 border-bottom py-1">
          <v-col cols="2" class="pa-2">
            <v-img
              :src="require('@/assets/profile/list.svg')"
            ></v-img>
          </v-col>
          <v-col cols="10">
            <p class="title-detail">{{$t('titleStudentMembership3')}}</p>
                <p class="describe-detail mb-0">{{$t('describeStudentMembership3')}}</p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="footer">
      <v-row>
        <v-col class="text-center">
          <v-btn
            outlined
            rounded
            color="primary"
            width="80%"
            @click="clickCancelMembership()"
          >
            {{ $t("CancelMembership") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import NotificationPopup from "@/components/NotificationPopup.vue";

export default {
  name: "StudentMembership",
  components: { NotificationPopup, },
  data() {
    return {
      dialogCancelMembership:false,
    };
  },
  methods:{
    clickCancelMembership(){
        this.dialogCancelMembership = true;
    },

    clickYesCancelMembership(){
        this.dialogCancelMembership = false;
        // this action delete membership
    },

    clickNoCancelMembership(){
        this.dialogCancelMembership = false;
        // this action delete membership
    },
    ClickReqStudent(){
      if(this.$store.state.auth.user_role == 'PASSENGER-BASIC' ){
        this.$router.push({
          name: 'ReqStudent',
        })
      }else if(this.$store.state.auth.user_role == 'PASSENGER-STUDENT' ){
        this.$router.push({
          name: 'PremiumMember',
        })
      }else{
        this.$router.push({
          name: 'PremiumMember',
        })
      }
    }
  },
  mounted(){
    
    // console.log("as",this.$store.state.passenger.checkKyc.length)
    // if(this.$store.state.passenger.checkKyc.length > 0){

    // }
  },

};
</script>

<style lang="scss" scoped>
.main {
  background: white!important;
}
.v-main__wrap {
  background: white!important;
}
.StudentMembership {
  background: #fff;
}
.border-bottom {
  border-bottom : 1px solid #F5F5F5;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;
  text-align: center;
  background: #ffffff;
  mix-blend-mode: normal;
}
.item-add {
  margin-bottom: 70px;
}
/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
    .onHover{
        cursor: pointer;
    }

    .image-head{
        justify-content: center;
        width: 50%;
        height: 30%;
        margin-top: 50px;
        margin-bottom: 30px;
    }
    .img-premium{
        margin-top: -40%;
        width: 100%;
        height: 35%;
    }
    .describe-null-active{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    align-items: center;
    text-align: center;

    color: #868686;
    }

    .title-no-promotion{
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        align-items: center;
        text-align: center;
    }

    .title-student-membership{
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
    }

    .img-add{
        height: auto;
        width: 30px;
    }

    .describe-student-membership{
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
    }
    .title-detail{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
    }

    .describe-detail{
        margin-top:-10px;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
    }

  .btn-add{
    position: fixed;
    left: 10%;
    bottom: 0;
    width: 80%;
    margin-bottom: 10%;

    text-transform: capitalize;
  }
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
    .onHover{
        cursor: pointer;
    }

    .describe-null-active{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #868686;
    }

    .title-no-promotion{
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        align-items: center;
        text-align: center;
    }
    .image-head{
        justify-content: center;
        width: 70%;
        height: 50%;
        margin-top: 100px;
        margin-bottom: 30px;
    }

    .img-premium{
        margin-top: -40%;
        width: 100%;
        height: 50%;
    }
    .title-student-membership{
        width: 248px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
    }

    .describe-student-membership{
        width: 193px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
    }

  .img-add{
    margin-top:7%;
    width: 80px;
    height: auto;
  }

    .title-detail{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
    }
    .describe-detail{
        margin-top:-10px;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
    }

  .btn-add{
    position: fixed;
    left: 10%;
    bottom: 0;
    width: 80%;
    margin-bottom: 10%;

    text-transform: capitalize;
  }
}
</style>